module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://md-admin.mobydigg.de/graphql","verbose":true,"schema":{"queryDepth":10,"typePrefix":"Wp","timeout":30000,"circularQueryLimit":5,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":false,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":null},"MediaItem":{"localFile":{"requestConcurrency":2,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"CoreParagraphBlockAttributes":{"exclude":true},"CoreParagraphBlockAttributesV2":{"exclude":true},"CorePullquoteBlockAttributes":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mobydigg.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":true,"googleAnalytics":{"trackingId":"G-D9DQKYNPQ7","autoStart":false,"anonymize":true,"controlCookieName":"MDCookieConsent","cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"AW-954438031","anonymize":true,"controlCookieName":"MDCookieConsent","cookieFlags":"secure;samesite=none"},"hotjar":{"trackingId":"3226432","snippetVersion":"6","controlCookieName":"MDCookieConsent"},"facebookPixel":{"pixelId":"1182033139165573","controlCookieName":"MDCookieConsent"},"environments":["production","development"]},
    }]
